import React from 'react';

const ScreenContent = (props) => {
    return(
        <div className='screen-content'>
            <h2 className='title'>{props.title}</h2>
            <p className='content'>
                {props.content}
            </p>
            {props.logo ? 
                <img className='img' src="./images/Logos/LeafPhoto.png" alt="" />
                :    
                <img className='img' src="./images/Logos/Fernbrook_Logo.png" alt="" />
           }
        </div>
    )
}

export default ScreenContent