// NPM Modules
import React, { Component } from 'react';
// Components
import Screen1 from './containers/Screen1'
import Screen2 from './containers/Screen2'
import Screen3 from './containers/Screen3'
import Screen4 from './containers/Screen4'
import Screen5 from './containers/Screen5'
import Screen6 from './containers/Screen6'
import Screen7 from './containers/Screen7'
import Screen8 from './containers/Screen8'
import Navigation from './components/Navigation'
// Data
import HomeData from './houseList.json'
class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      screen1: true,
      screen2: false,
      screen3: false,
      screen4: false,
      screen5: false,
      screen6: false,
      screen7: false,
      screen8: false,
      homeList: [],
      currentHome: 0
    }
  }

  // When loaded, imports the data from JSON list 
  componentDidMount(){
    this.setState({ homeList: HomeData.forestCreekHomes })
  }

  // Toggles which house is selected to view floorplans
  toggleCurrentHomes = (home) => {
    this.setState({ currentHome: home })
  }

  // Toggles between different pages
  togglePageHandler = (page) => {
    let resetState = {
      screen1: false,
      screen2: false,
      screen3: false,
      screen4: false,
      screen5: false,
      screen6: false,
      screen7: false,
      screen8: false,
    }

    let newState = {
      ...resetState,
      [page]: true
    }

    this.setState(newState)
  }

  toggleViewandSelectHome = (home) => {
    this.togglePageHandler('screen4')
    this.toggleCurrentHomes(home)
  }

  render() {
    return (
      <div className='TouchScreenContainer'>
        <Screen1 togglePage={this.togglePageHandler} currentState={this.state}/>
        <Screen2 currentState={this.state}/>
        <Screen3 
          currentState={this.state}
          toggleViewandSelectHome={this.toggleViewandSelectHome}
        />
        <Screen4 
          toggleCurrentHomes={this.toggleCurrentHomes}
          currentState={this.state} 
        />
        <Screen5 currentState={this.state}/>
        <Screen6 currentState={this.state}/>
        <Screen7 currentState={this.state}/>
        <Screen8 currentState={this.state}/>
        <Navigation togglePage={this.togglePageHandler} currentState={this.state}/>
      </div>
    );
  }
}

export default App;
