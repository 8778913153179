// Components
import React from 'react';
import ScreenTitle from '../components/ScreenTitle'
import FloorPlanSmall from '../components/FloorPlanSmall'

const Screen3 = (props) => {
    // Renders Homes In 45' Size
    let FortyFiveHomes 
    if (props.currentState.homeList.length <= 0){ FortyFiveHomes = [] }
    else{ FortyFiveHomes = props.currentState.homeList.filter((home) => home.size === 45 )}
    
    // Renders Homes In 50' Size
    let FiftyHomes
    if (props.currentState.homeList.length <= 0){ FiftyHomes = [] }
    else{ FiftyHomes = props.currentState.homeList.filter((home) => home.size === 50 )}

    return (
        <div className={props.currentState.screen3 ? 'screen3 touchscreen-bg' : 'screen3 touchscreen-bg hide-screen'}>
            <ScreenTitle 
                line1={'sophisticated spaces.'}
                line2={'timeless elegance.'}
            />
            <img src="./images/Logos/LeafPhoto.png" className='img' alt=""/>
            <div className='selection-container'>
                <ul>
                    {FortyFiveHomes.map((home, index) => {
                        return(
                            <li key={index}>
                                <FloorPlanSmall
                                    index={index} 
                                    data={home} 
                                    color={home.size}
                                    toggleViewandSelectHome={props.toggleViewandSelectHome}
                                />
                            </li>
                        )
                    })}
                </ul>
                <ul>
                    {FiftyHomes.map((home, index) => {
                        return(
                            <li key={index}>
                                <FloorPlanSmall
                                    index={FortyFiveHomes.length + index}
                                    data={home}
                                    color={home.size}
                                    toggleViewandSelectHome={props.toggleViewandSelectHome}
                                />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Screen3