import React from 'react' 
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Slider = (props) => {
    return (
        <div className='slider'>
            <Carousel
                autoPlay
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                dynamicHeight={true}
            >
                {props.images.map((image, index) => {
                    return (
                        <div key={index}>
                            <img src={image} alt=""/>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    )
}

export default Slider