import React from 'react';

const Navigation = (props) => {
    return(
        <div className={props.currentState.screen1 ? 'col row navigation hide-screen' : 'col row navigation'}>
            <div 
                className='col-2 navigation-item'
                onClick={() => { props.togglePage('screen1') }}
            >
                <img src="./images/Logos/TheWoodsCollectionLogo2.png" alt=""/>
            </div>
            <div className='col-10 navigation-item'>
                <div className='row'>
                    <div 
                        className={props.currentState.screen2 ? 'col navigation-buttons active-page' : 'col navigation-buttons'}
                        onClick={() => {props.togglePage('screen2')}}
                    >
                        Introduction
                    </div>
                    <div 
                        className={props.currentState.screen3 || props.currentState.screen4 ? 'col navigation-buttons active-page' : 'col navigation-buttons'}
                        onClick={() => {props.togglePage('screen3')}}
                    >
                        Floor Plans
                    </div>
                    <div 
                        className={props.currentState.screen5 ? 'col navigation-buttons active-page' : 'col navigation-buttons'}
                        onClick={() => {props.togglePage('screen5')}}
                    >
                        Site Plan
                    </div>
                    <div 
                        className={props.currentState.screen6 ? 'col navigation-buttons active-page' : 'col navigation-buttons'}
                        onClick={() => {props.togglePage('screen6')}}
                    >
                        Renderings
                    </div>
                    <div 
                        className={props.currentState.screen7 ? 'col navigation-buttons active-page' : 'col navigation-buttons'}
                        onClick={() => {props.togglePage('screen7')}}
                    >
                        Neighbourhood
                    </div>
                    <div 
                        className={props.currentState.screen8 ? 'col navigation-buttons active-page' : 'col navigation-buttons'}
                        onClick={() => {props.togglePage('screen8')}}
                    >
                        Builder Story
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navigation