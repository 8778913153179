// Components
import React from 'react'
import Navigation from '../components/Navigation'

const Screen4 = ( props ) => {
    const HomeList = props.currentState.homeList.map((home, index) => {
            return (
                <li 
                    key={index} 
                    onClick={() => {props.toggleCurrentHomes(index)}}
                >
                    <div className='homeContainer row'>
                        <div className={home.size === 45 ? 'houseTitle purple col-8' : 'houseTitle blue col-8'}>
                            <h1>{home.name}</h1>
                            <p>{home.sqFt}</p>
                        </div>
                        <div className='houseSize col-3'>
                            <h1>{home.size}'</h1>
                            <p>{home.style}</p>
                        </div>
                        <div className={props.currentState.currentHome === index ? "square" : "square hide"}></div>
                    </div>
                </li>
            )
    })
    let CurrentHomeInfo, CurrentHomeLayout

    // Used to prevent errors on undefined data as 
    // it takes time to load in content from JSON file
    if(props.currentState.homeList.length <= 0){
        CurrentHomeInfo = {
            "name": "Loading...",
            "size": "--",
            "style": "--",
            "sqFt": "--",
            "basementSqFt": "--",
            "images": [
                "--",
                "--"
            ],
            "floorPlanImage": "--"
        }
    }else{
        CurrentHomeInfo = props.currentState.homeList[props.currentState.currentHome]
        CurrentHomeLayout = CurrentHomeInfo.floorPlanImages.map((image, index) => {
            return(
                <li key={index}>
                    <img src={image} alt=""/>
                </li>
            )
        })
    }


    return(
        <div className={props.currentState.screen4 ? 'screen4 touchscreen-bg' : 'screen4 touchscreen-bg hide-screen'}>
        <img src="./images/Logos/LeafPhoto.png" className='img' alt="" />
            <div className='floorPlanContainer row'>
                <div className='col-3'>
                    <ul>
                        {HomeList}
                    </ul>
                </div>
                <div className='col-9'>
                    <div className={CurrentHomeInfo.size === 45 ? 'floorPlan_header purple' : 'floorPlan_header blue'}>
                        <div className='size'>
                            <h1>{ CurrentHomeInfo.size }'</h1>
                            <p>{ CurrentHomeInfo.style }</p>
                        </div>
                        <div className='information'>
                            <h1>
                                { CurrentHomeInfo.name }   
                                 <span className="elevation">
                                    { CurrentHomeInfo.sqFt } 
                                    { CurrentHomeInfo.basementSqFt }
                                </span>
                            </h1>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'>
                            <img className="floorPlan_home_image" src={ CurrentHomeInfo.images[0] } alt=""/>
                            <img className="floorPlan_home_image" src={ CurrentHomeInfo.images[1] } alt=""/>
                        </div>
                        <div className='col-8 floorplan-container'>
                            <h2>Scroll To View All Layouts</h2>
                            <ul>
                                { CurrentHomeLayout }
                            </ul>
                         </div>
                    </div>
                </div>
            </div>
            <Navigation currentState={props.currentState} />
        </div>
    )
}

export default Screen4