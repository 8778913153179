// Components
import React from 'react';

const Screen1 = (props) => {
    return(
        <div 
            className={props.currentState.screen1 ? 'screen1 touchscreen-bg' : 'screen1 touchscreen-bg hide-screen'}
            style={{
                backgroundImage: "url('./images/BackgroundTrees.png')",
                position: 'relative',
                backgroundRepeatY: 'no-repeat',
            }}
        >
            <div className='row content'>
                <div className='col-7'>
                    <img src="./images/Logos/TheWoodsCollectionLogo2.png" alt='The Woods Collection At Forest Creek Logo'/>
                </div>
                <div className='row col-5 landing-navigation'>
                    <div className='col-6'>
                        <div 
                            onClick={() => props.togglePage('screen2')}
                            className='landing-navigation-item'
                        >
                            introduction
                        </div>
                        <div 
                            onClick={() => props.togglePage('screen5')}
                            className='landing-navigation-item'
                        >
                            site plan
                        </div>
                        <div 
                            onClick={() => props.togglePage('screen7')}
                            className='landing-navigation-item'
                        >
                            neighbourhood
                        </div>
                    </div>
                    <div className='col-6'>
                        <div 
                            onClick={() => props.togglePage('screen3')}
                            className='landing-navigation-item'
                        >
                            floor plans
                        </div>
                        <div 
                            onClick={() => props.togglePage('screen6')}
                            className='landing-navigation-item'
                        >
                            renderings
                        </div>
                        <div 
                            onClick={() => props.togglePage('screen8')}
                            className='landing-navigation-item'
                        >
                            builder story
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    )
}

export default Screen1