// Components
import React from 'react'
import ScreenContent from '../components/ScreenContent'
import ScreenTitle from '../components/ScreenTitle'
import Slider from '../components/ImageSlider'
// Page Data
import Data from '../siteContent.json'

const Screen6 = (props) => {
    return (
        <div className={props.currentState.screen6 ? 'screen6 touchscreen-bg' : 'screen6 touchscreen-bg hide-screen'}>
            <div className='row'>
                <ScreenTitle 
                    line1={Data.Screen6.title[0]}
                    line2={Data.Screen6.title[1]}
                    line3={Data.Screen6.title[2]}
                    line4={Data.Screen6.title[3]}
                />
            </div>
            <div className='row'>
                <div className='col-3'>
                    <ScreenContent 
                        title={Data.Screen6.contentTitle}
                        content={Data.Screen6.content}
                    />
                </div>
                <div className='col-9'>
                    <Slider images={
                        [
                            "./images/Screen6/Screen6_Image1.jpg", 
                            "./images/Screen6/Screen6_Image2.jpg", 
                            "./images/Screen6/Screen6_Image3.jpg", 
                            "./images/Screen6/Screen6_Image4.jpg"
                        ]
                    }/>
                </div>
            </div>
        </div>
    )
}

export default Screen6