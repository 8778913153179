// Components
import React from 'react'
import ScreenTitle from '../components/ScreenTitle'
import ScreenContent from '../components/ScreenContent'
// Data
import Data from '../siteContent.json'

const Screen7 = (props) => {
    return (
        <div className={props.currentState.screen7 ? 'screen7 touchscreen-bg' : 'screen7 touchscreen-bg hide-screen'}>
            <div className='row'>
                <ScreenTitle
                    line1={Data.Screen7.title[0]}
                    line2={Data.Screen7.title[1]}
                    line3={Data.Screen7.title[2]}
                />
                <ScreenContent 
                    title={Data.Screen7.contentTitle}
                    content={Data.Screen7.content} 
                />
            </div>
            <img src="./images/Screen7/Screen7_Image1.png" id="image1" alt="" />
            <img src="./images/Screen7/Screen7_Image2.png" id="image2" alt="" />
            <img src="./images/Screen7/Screen7_Image3.png" id="image3" alt="" />
            <img src="./images/Screen7/Screen7_Image4.png" id="image4" alt="" />
            <img src="./images/Screen7/Screen7_Image5.png" id="image5" alt="" />
            <img src="./images/Screen7/Screen7_Image6.png" id="image6" alt="" />
            <img src="./images/Screen7/Screen7_Image7.png" id="image7" alt="" />
            <img src="./images/Screen7/Screen7_Image8.png" id="image8" alt="" />
            <img src="./images/Screen7/Screen7_Image9.png" id="image9" alt="" />
        </div>
    )
}

export default Screen7