import React from 'react' 

const FloorPlanSmall = (props) => {
    let styling = props.color === 45 ? 'floorplan-small purple' :  'floorplan-small blue'
    return(
        <div 
            className={ styling }
            onClick={() => { props.toggleViewandSelectHome(props.index) }}
        >
            <div className='row'>
                <div className='col-8'></div>
                <div className='col-4 floorplan-size'>
                    <h1>{ props.data.size }'</h1>
                    <h3>{ props.data.style }</h3>
                </div>
            </div>
            <h2 className='title'>{ props.data.name }</h2>
            <p className='stats'>{ props.data.sqFt }</p>
            <p className='view-plan'>
                ( view plan )
            </p>
        </div>
    )
}

export default FloorPlanSmall