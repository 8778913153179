// Component
import React from 'react'
import ScreenContent from '../components/ScreenContent'
import ScreenTitle from '../components/ScreenTitle'

// Data
import Data from '../siteContent.json'

const Screen8 = (props) => {
    return (
        <div className={props.currentState.screen8 ? 'screen8 touchscreen-bg' : 'screen8 touchscreen-bg hide-screen'}>
            <div className='row'>
                <ScreenTitle
                    line1={Data.Screen8.title[0]}
                    line2={Data.Screen8.title[1]}
                    line3={Data.Screen8.title[2]}
                />
            </div>
            <div className='row'>
                <div className='col-3'>
                    <ScreenContent
                        title={Data.Screen8.contentTitle}
                        content={Data.Screen8.content}
                        logo
                    />
                </div>
                <div className='col-9 image-container'>
                    <img src="./images/Screen8/Screen8_Image1.jpg" id='img1' alt="" />
                    <img src="./images/Screen8/Screen8_Image2.jpg" id='img2' alt="" />
                    <img src="./images/Screen8/Screen8_Image3.jpg" id='img3' alt="" />
                    <img src="./images/Screen8/Screen8_Image4.jpg" id='img4' alt="" />
                    <img src="./images/Screen8/Screen8_Image5.jpg" id='img5' alt="" />
                </div>
            </div>
        </div>
    )
}

export default Screen8