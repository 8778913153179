// Components
import React from 'react'
import ScreenContent from '../components/ScreenContent'
import ScreenTitle from '../components/ScreenTitle'

// Data
import Data from '../siteContent.json'

const Screen5 = (props) => {
    // console.log(Data)
    return (
        <div className={props.currentState.screen5 ? 'screen5 touchscreen-bg' : 'screen5 touchscreen-bg hide-screen'}>
            <div className='row'>
                <ScreenTitle
                    line1={Data.Screen5.title[0]}
                    line2={Data.Screen5.title[1]}
                    line3={Data.Screen5.title[2]}
                />
                <div className='col-3'>
                    <ScreenContent 
                        title={Data.Screen5.contentTitle}
                        content={Data.Screen5.content}
                    />
                </div>
                <div className='col-9 image-container'>
                    <img src="./images/Forrest_Creek_Siteplan.png" alt='' />
                </div>
            </div>
        </div>
    )
}

export default Screen5