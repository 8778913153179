import React from 'react';

const ScreenTitle = (props) => {
    return(
        <h2 className='screen-title'>
            {props.line1}<br />
            {props.line2}<br />
            {props.line3}<br />
            {props.line4}
        </h2>
    )
}

export default ScreenTitle