// Components
import React from 'react';
import ScreenContent from '../components/ScreenContent'
import ScreenTitle from '../components/ScreenTitle'

// Data
import Data from '../siteContent.json'

const Screen2 = (props) => {
    return (
        <div className={props.currentState.screen2 ? 'screen2 touchscreen-bg' : 'screen2 touchscreen-bg hide-screen'}>
            <div className='row'>
                <ScreenTitle 
                    line1={Data.Screen2.title[0]}
                    line2={Data.Screen2.title[1]}
                />
                <div className='col-3'>
                    <ScreenContent
                        title={Data.Screen2.contentTitle}
                        content={Data.Screen2.content}
                    />
                </div>
                <div className='col-9 image-container'>
                    <img id='image1' src="./images/Screen2/Screen2_Image1.png" alt=""/>
                    <img id='image2' src="./images/Screen2/Screen2_Image2.png" alt=""/>
                    <img id='image3' src="./images/Screen2/Screen2_Image3.png" alt=""/>
                </div>
            </div>
        </div>
    )
}

export default Screen2